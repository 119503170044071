import React from 'react';
import metaRoutes from 'admin/meta_routes';

const Dashboard = React.lazy(() => import('admin/views/Dashboard/Dashboard'));
const CreateReport = React.lazy(() => import('admin/views/CreateReport/ReportList'));
const FileDump = React.lazy(() => import('./views/FileDump'));

const Roles = React.lazy(() => import('admin/views/Roles/Roles'));
const AddRole = React.lazy(() => import('admin/views/Roles/AddRole'));
const EditRole = React.lazy(() => import('admin/views/Roles/EditRole'));
const ViewRole = React.lazy(() => import('admin/views/Roles/ViewRole'));

const Birds = React.lazy(() => import('admin/views/Birds/List'));
const AddBird = React.lazy(() => import('admin/views/Birds/Add'));
const EditBird = React.lazy(() => import('admin/views/Birds/Edit'));
const ViewBird = React.lazy(() => import('admin/views/Birds/View'));

const Checklists = React.lazy(() => import('admin/views/Checklists/List'));
const ViewChecklist = React.lazy(() => import('admin/views/Checklists/View'));
const ChecklistMap = React.lazy(() => import("admin/views/Checklists/ChecklistMap"));

const Users = React.lazy(() => import('admin/views/Users/Users'));
const AddUsers = React.lazy(() => import('admin/views/Users/AddUser'));
const EditUsers = React.lazy(() => import('admin/views/Users/EditUser'));

const AboutUs = React.lazy(() => import('admin/views/AboutUs/Add'));

const exportRoutes = [
  { path: metaRoutes.home, exact: true, name: 'Dashboard', component: Dashboard },
  { path: metaRoutes.aboutUs, exact: true, name: 'About Us', component: AboutUs, permission: 'other.aboutUs' },
  { path: metaRoutes.createReports, exact: true, name: 'Create Report', component: CreateReport, permission: 'isAdmin' },
  { path: metaRoutes.fileDump, exact: true, name: 'File Upload', component: FileDump, permission: 'other.fileDump' },
  
  { path: metaRoutes.roles, exact: true, name: 'Roles', component: Roles, permission: 'role.view' },
  { path: metaRoutes.rolesAdd, exact: true, name: 'Add Role', component: AddRole, permission: 'role.add' },
  { path: metaRoutes.rolesEdit, exact: true, name: 'Edit Role', component: EditRole, permission: 'role.edit' },
  { path: metaRoutes.rolesView, name: 'View Role', component: ViewRole, permission: 'role.view' },
  
  { path: metaRoutes.users, exact: true, name: 'Users', component: Users, permission: 'user.view' },
  { path: metaRoutes.usersAdd, exact: true, name: 'Add Users', component: AddUsers, permission: 'user.add' },
  { path: metaRoutes.usersEdit, exact: true, name: 'Edit Users', component: EditUsers, permission: 'user.edit' },

  { path: metaRoutes.birds, exact: true, name: 'Bird', component: Birds, permission: 'bird.view' },
  { path: metaRoutes.birdAdd, exact: true, name: 'Add Bird', component: AddBird, permission: 'bird.add' },
  { path: metaRoutes.birdEdit, exact: true, name: 'Edit Bird', component: EditBird, permission: 'bird.edit' },
  { path: metaRoutes.birdView, name: 'View Bird', component: ViewBird, permission: 'bird.view' },

  { path: metaRoutes.checklists, exact: true, name: 'Checklist', component: Checklists, permission: 'checklist.view' },
  { path: metaRoutes.checklistView, name: 'View Checklist', component: ViewChecklist, permission: 'checklist.view' },
  { path: metaRoutes.checklistMap, name: 'Checklist Map', component: ChecklistMap, permission: 'checklist.view' },
  
];

export default exportRoutes;
